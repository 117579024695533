import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageSection from "../components/page-section"
import Figure from "../components/figure"

const IllustrationPage = props => (
  <Layout>
    <SEO title="Illustration" />

    <PageSection content={{ heading: 'Illustration' }} >
      <Figure content={{ figcaption: 'Soft drink packaging for „Kick“, approx. 1981' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imageIll001.childImageSharp.fluid} alt="Illustration: „Kick“" />
      </Figure>
      <Figure content={{ figcaption: 'Greeting card for „Bonbon Cartes“, approx. 1981' }} options={{ lightOnDark: true, sizeS: true }} >
        <Img fluid={props.data.imageIll002.childImageSharp.fluid} alt="Illustration: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, undated' }} options={{ lightOnDark: true, sizeS: true }} >
        <Img fluid={props.data.imageIll003.childImageSharp.fluid} alt="Illustration: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, approx. 1984' }} options={{ lightOnDark: true }} >
        <Img fluid={props.data.imageIll004.childImageSharp.fluid} alt="Illustration: Untitled" />
      </Figure>
      <Figure content={{ figcaption: 'Virgo zodiac for „Cosmopolitan Magazine“, undated' }} options={{ lightOnDark: true, sizeXs: true }} >
        <Img fluid={props.data.imageIll005.childImageSharp.fluid} alt="Illustration: „Virgo“" />
      </Figure>
      <Figure content={{ figcaption: 'Untitled, 1989' }} options={{ lightOnDark: true, sizeS: true }} >
        <Img fluid={props.data.imageIll006.childImageSharp.fluid} alt="Illustration: Untitled" />
      </Figure>
    </PageSection>
  </Layout>
)

export default IllustrationPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 768) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageIll001: file(relativePath: { eq: "illustration-001.jpg" }) {
      ...fluidImage
    }
    imageIll002: file(relativePath: { eq: "illustration-002.jpg" }) {
      ...fluidImage
    }
    imageIll003: file(relativePath: { eq: "illustration-003.jpg" }) {
      ...fluidImage
    }
    imageIll004: file(relativePath: { eq: "illustration-004.jpg" }) {
      ...fluidImage
    }
    imageIll005: file(relativePath: { eq: "illustration-005.jpg" }) {
      ...fluidImage
    }
    imageIll006: file(relativePath: { eq: "illustration-006.jpg" }) {
      ...fluidImage
    }
  }
`;